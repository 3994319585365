import useSWR from 'swr'
import { getPaginationAndFilterQueryString } from 'shared/api/utils/get-pagination-and-filter-query-string'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import { SwrCountInterface, SwrPaginationInterface } from 'shared/types/swr-pagination-interface'
import { MEMBERSHIP_API } from '../api/membership-api'
import {
  CourseEnrollmentsFilterInterface,
  SchoolEnrollmentsApiInterface,
} from '../types/school-enrollments-interface'

interface UseEnrollmentsInterface extends SwrPaginationInterface {
  afterFetch?: (
    data: SchoolEnrollmentsApiInterface,
  ) => Promise<SchoolEnrollmentsApiInterface> | SchoolEnrollmentsApiInterface
  shouldFetch?: boolean
  filter: CourseEnrollmentsFilterInterface
}

export function useEnrollments({
  shouldFetch = true,
  limit,
  pagination,
  filter,
  afterFetch,
}: UseEnrollmentsInterface) {
  const { user } = useUser()
  const { fetcher: listFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    SchoolEnrollmentsApiInterface
  >({ method: RequestMethodsEnum.get, afterFetch })
  const { fetcher: countFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    SwrCountInterface
  >({
    method: RequestMethodsEnum.get,
  })

  const isUserAuthorised = isAuthorizedUser(user)

  const { data: enrollments, mutate } = useSWR<SchoolEnrollmentsApiInterface>(
    () =>
      user &&
      isUserAuthorised &&
      shouldFetch &&
      `${MEMBERSHIP_API}/enrollments/list?${getPaginationAndFilterQueryString({
        limit,
        pagination,
        // @ts-ignore
        filter,
      })}`,
    listFetcher,
  )

  const { data: counter } = useSWR<SwrCountInterface>(() => {
    user && `${MEMBERSHIP_API}/count?pagination[limit]`
  }, countFetcher)

  return { enrollments, mutate, counter }
}
