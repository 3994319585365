import React from 'react'
import { useTranslation } from 'next-i18next'
import PrimaryButton from 'shared/components/primary-button'
import ProgressBar from 'shared/components/progress-bar'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import ArrowLeftIcon from 'shared/icons/arrow-left'
import FilesIcon from 'shared/icons/files-icon'
import { dateTimeFormat } from 'modules/profile/utils/date-time-format'
import { SchoolCourseEnrollmentsDataInterface } from 'modules/school/types/school-enrollments-interface'
import { getCourseCardLinkTarget } from 'modules/school/utils/get-course-card-link-target'
import defaultCourseCover from '../../../../../public/images/default-course-cover.png'

export interface CourseCardProps {
  data: SchoolCourseEnrollmentsDataInterface
}

export const CourseCard = ({ data }: CourseCardProps) => {
  const { t } = useTranslation()

  const { user } = useUser()

  const button = !data.accessFrom && (
    <PrimaryButton
      width="small"
      className="mt-3 w-full min-w-[120px] self-start sm:mt-0 sm:w-[unset]"
      href={data.resumeUrl}
      target={getCourseCardLinkTarget(data.resumeUrl)}
    >
      {!data.progress ? t('school.card.button.open_course') : t('school.card.button.resume_course')}
      <ArrowLeftIcon className="rotate-180" stroke="white" />
    </PrimaryButton>
  )

  return (
    <div className={`flex flex-col rounded-2xl md:flex-row`}>
      <div className="w-full md:w-[550px]">
        {/* NOTE: this is the hack for displaying an empty image with the 16/9 ratio */}
        <div className="relative w-full" style={{ paddingTop: 'calc(100% * 9 / 16)' }}>
          <div className="absolute left-0 top-0 h-full w-full">
            <img
              className={`w-full rounded-lg bg-contain bg-no-repeat`}
              src={data.image?.path || defaultCourseCover.src}
            />
          </div>
        </div>
      </div>
      <div className="mt-4 flex basis-full flex-col md:mt-0 md:pl-6">
        <div>
          <div className="flex flex-col-reverse justify-between gap-4 sm:flex-row">
            <div>
              <h5 className={`text-lg font-bold text-darkblue sm:text-xl`}>{data.name}</h5>
              {data.instructorName && (
                <p
                  className={`mt-2 text-base font-normal text-blue ${
                    !data.instructorName ? 'opacity-0' : ''
                  }`}
                >
                  {data.instructorName || '.'}
                </p>
              )}
            </div>

            <div className="hidden sm:block">{button}</div>
          </div>
          <p className={`mt-3 text-base text-[gray-300] text-darkblue/70`}>{data.description}</p>
        </div>

        <div className="mt-auto flex flex-wrap justify-between gap-2 sm:flex-nowrap">
          {data.accessFrom && isAuthorizedUser(user) ? (
            <p className={`'text-darkblue/70 mt-2 text-sm text-[gray-300]`}>
              {t('school.card.waiting_for_access', {
                date: dateTimeFormat(data.accessFrom, {
                  timeFormat: user?.timeFormat,
                  dateFormat: user?.dateFormat,
                  timeZone: user?.timezone,
                }),
              })}
            </p>
          ) : (
            <ProgressBar className="mt-3" progress={data.progress} theme={'dark_blue'} />
          )}
          <div className="flex justify-end sm:mt-3 sm:basis-[200px]">
            <span className={`flex items-center gap-1 text-sm text-gray-400 sm:text-base`}>
              <FilesIcon />
              {t('school.card.lessons_counter', {
                progress: `${!data.accessFrom ? `${data.completedLecturesCount}/` : ''}${
                  data.totalLecturesCount
                }`,
              })}
            </span>
          </div>
        </div>
        {<div className="sm:hidden">{button}</div>}
      </div>
    </div>
  )
}
