import React from 'react'
import { useTranslation } from 'next-i18next'
import PrimaryButton from 'shared/components/primary-button'
import ProgressBar from 'shared/components/progress-bar'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import ArrowLeftIcon from 'shared/icons/arrow-left'
import FilesIcon from 'shared/icons/files-icon'
import { dateTimeFormat } from 'modules/profile/utils/date-time-format'
import { getCourseCardLinkTarget } from 'modules/school/utils/get-course-card-link-target'
import defaultCourseCover from '../../../../../public/images/default-course-cover.png'
import { SCHOOL_CARD_GRADIENT_OVERLAY_CLASSNAME } from '../constants/school-card-gradient-overlay-classname'
import { CourseBundleBadge } from './course-bundle-bage'
import { SchoolCardProps, SchoolCardTypeEnum } from './types'
import { Wrapper } from './wrapper'

export const SchoolCard = ({ isSM, data, type, onOpenCourseBundleModal }: SchoolCardProps) => {
  const { t } = useTranslation()

  const { user } = useUser()

  const getButtonProps = () => {
    if (type === SchoolCardTypeEnum.bundle) {
      return {
        onClick: onOpenCourseBundleModal,
      }
    }
    const href = type === SchoolCardTypeEnum.course ? data.resumeUrl : data.courseUrl
    return {
      href,
      target: getCourseCardLinkTarget(href),
    }
  }

  return (
    <Wrapper
      className={`flex flex-col rounded-2xl ${
        type === SchoolCardTypeEnum.bundle ? 'bg-darkblue' : 'bg-white'
      } ${!isSM && type === SchoolCardTypeEnum.bundle ? 'cursor-pointer' : ''}`}
      isLink={type !== SchoolCardTypeEnum.bundle}
      {...(!isSM ? getButtonProps() : {})}
    >
      {/* NOTE: this is the hack for displaying an empty image with the 16/9 ratio */}
      <div className="relative" style={{ paddingTop: 'calc(100% * 9 / 16)' }}>
        <div
          className={`absolute left-0 top-0 h-full w-full ${
            type === SchoolCardTypeEnum.bundle ? SCHOOL_CARD_GRADIENT_OVERLAY_CLASSNAME : ''
          }`}
        >
          {type === SchoolCardTypeEnum.bundle && (
            <CourseBundleBadge
              className="absolute right-4 top-4"
              onClick={onOpenCourseBundleModal}
            />
          )}
          <img
            className={`w-full rounded-t-2xl bg-contain bg-no-repeat`}
            src={data.image?.path || defaultCourseCover.src}
          />
        </div>
      </div>
      <div className="px-6 pt-6">
        <h5
          className={`text-xl font-bold ${
            type === SchoolCardTypeEnum.bundle ? 'text-white' : 'text-darkblue'
          }`}
        >
          {data.name}
        </h5>
        <p
          className={`mt-2 text-base font-normal text-blue ${
            !data.instructorName ? 'opacity-0' : ''
          }`}
        >
          {data.instructorName || '.'}
        </p>
        <p
          className={`mt-2 max-h-28 overflow-y-auto whitespace-pre-line break-words text-base [&::-webkit-scrollbar-thumb]:bg-opacity-0 [&::-webkit-scrollbar]:transition-opacity [&::-webkit-scrollbar]:[width:4px] [&:hover::-webkit-scrollbar-thumb]:bg-opacity-100 ${
            type === SchoolCardTypeEnum.bundle
              ? '[&::-webkit-scrollbar-thumb]:bg-blue'
              : '[&::-webkit-scrollbar-thumb]:bg-gray'
          } } ${type === SchoolCardTypeEnum.bundle ? 'text-gray-400' : 'text-darkblue/70'}`}
        >
          {data.description}
        </p>
      </div>
      <div className="mt-auto px-6 pb-6">
        {type !== SchoolCardTypeEnum.explore_course &&
          (type === SchoolCardTypeEnum.course && data.accessFrom && isAuthorizedUser(user) ? (
            <p className={`mt-2 text-sm text-[gray-300] text-darkblue/70`}>
              {t('school.card.waiting_for_access', {
                date: dateTimeFormat(data.accessFrom, {
                  timeFormat: user?.timeFormat,
                  dateFormat: user?.dateFormat,
                  timeZone: user?.timezone,
                }),
              })}
            </p>
          ) : (
            <ProgressBar
              className="mt-6 w-full"
              progress={data.progress}
              theme={type === SchoolCardTypeEnum.bundle ? 'light_blue' : 'dark_blue'}
            />
          ))}
        <div className="mt-8 flex justify-between">
          <span className={`flex items-center gap-1 text-base text-gray-400`}>
            <FilesIcon fill={type === SchoolCardTypeEnum.bundle ? 'white' : '#142D63'} />
            {t(
              type === SchoolCardTypeEnum.bundle
                ? 'school.card.courses_counter'
                : 'school.card.lessons_counter',
              {
                progress: `${
                  type === SchoolCardTypeEnum.course || type === SchoolCardTypeEnum.bundle
                    ? `${
                        type === SchoolCardTypeEnum.course
                          ? data.completedLecturesCount
                          : data.completedCoursesCount
                      }/`
                    : ''
                }${
                  type === SchoolCardTypeEnum.bundle
                    ? data.totalCoursesCount
                    : data.totalLecturesCount
                }`,
              },
            )}
          </span>
          {!(type === SchoolCardTypeEnum.course && data.accessFrom) && (
            <PrimaryButton {...getButtonProps()}>
              {type === SchoolCardTypeEnum.course || type === SchoolCardTypeEnum.bundle
                ? !data.progress
                  ? t('school.card.button.open_course')
                  : t('school.card.button.resume_course')
                : t('school.card.button.get_access_to_course')}
              <ArrowLeftIcon className="rotate-180" stroke="white" />
            </PrimaryButton>
          )}
        </div>
      </div>
    </Wrapper>
  )
}
