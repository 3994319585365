import React, { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { PageTab } from 'shared/components/page-tab/page-tab'
import { PageTabGroup } from 'shared/components/page-tab/page-tab-group'
import { PageTabList } from 'shared/components/page-tab/page-tab-list'
import { PageTabPanel } from 'shared/components/page-tab/page-tab-panel'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import { limitObj_12_24_48 } from 'shared/route-query/constants/pagination-limit-array'
import { useFilterPagination } from 'shared/route-query/hooks/use-filter-pagination'
import { baseServerSideProps } from 'shared/utils/base-server-side-props'
import { EnrollmentsTab } from 'modules/school/components/enrollments-tab'
import {
  EnrollmentsTabFilter,
  defaultFilter,
} from 'modules/school/components/enrollments-tab-filter'
import { ExploreCoursesTab } from 'modules/school/components/explore-courses-tab'
import { useEnrollments } from 'modules/school/hooks/use-enrollments'
import { getSchoolLayout } from 'modules/school/layout/school-layout'
import { SchoolTabsEnum } from 'modules/school/types/school-interface'

function School() {
  const { t } = useTranslation()

  const [isCheckedIfShouldRedirect, setIsCheckedIfShouldRedirect] = useState(false)

  const router = useRouter()
  const { tab } = router.query

  const { user } = useUser()
  const { limit, setLimit, pagination, setPagination, filter, setFilter } = useFilterPagination({
    defaultFilter,
    isStaticUrl: true,
    initLimit: limitObj_12_24_48.short,
  })

  const isUserAuthorised = isAuthorizedUser(user)

  const { enrollments } = useEnrollments({
    limit,
    pagination,
    filter,
    afterFetch: data => {
      if (
        !isCheckedIfShouldRedirect &&
        !filter.searchBy &&
        tab === SchoolTabsEnum.enrollments &&
        !data.items.length
      ) {
        router.replace({
          query: { tab: SchoolTabsEnum.explore_courses },
        })
      }
      setIsCheckedIfShouldRedirect(true)
      return data
    },
    shouldFetch: tab === SchoolTabsEnum.enrollments,
  })

  useEffect(() => {
    // NOTE: prevent redirection if user go to "enrollments" from the "explore_courses"
    if (tab === SchoolTabsEnum.explore_courses) {
      setIsCheckedIfShouldRedirect(true)
    }
  }, [tab])

  useEffect(() => {
    if (user) {
      if (!isUserAuthorised && tab !== SchoolTabsEnum.explore_courses)
        router.replace({
          query: { tab: SchoolTabsEnum.explore_courses },
        })
      else if (!tab) {
        router.replace({
          query: { tab: SchoolTabsEnum.enrollments },
        })
      }
    }
  }, [router, tab, user, isUserAuthorised])

  return (
    <div>
      <PageTabGroup selectedIndex={Object.values(SchoolTabsEnum).findIndex(el => el === tab)}>
        <PageTabList
          className="min-h-[75px] flex-col md:flex-row"
          buttonBar={
            user &&
            tab !== SchoolTabsEnum.explore_courses && (
              <EnrollmentsTabFilter
                className="hidden md:flex"
                inputClassName="bg-lightblue"
                filter={filter}
                setFilter={setFilter}
              />
            )
          }
        >
          <PageTab
            className={!user || !isUserAuthorised ? 'hidden' : ''}
            onClick={() => {
              setFilter(prev => ({ ...prev, searchBy: '' }))
              router.push({
                query: { tab: SchoolTabsEnum.enrollments },
              })
            }}
          >
            {t('school.tabs.enrollments_title')}
          </PageTab>

          <PageTab
            className={!user ? 'hidden' : ''}
            onClick={() =>
              router.push({
                query: { tab: SchoolTabsEnum.explore_courses },
              })
            }
          >
            {t('school.tabs.explore_courses_title')}
          </PageTab>
        </PageTabList>

        <PageTabPanel>
          <EnrollmentsTab
            enrollments={enrollments}
            limit={limit}
            setLimit={setLimit}
            pagination={pagination}
            setPagination={setPagination}
            filter={filter}
            filterComponent={
              <EnrollmentsTabFilter
                className="flex md:hidden"
                filter={filter}
                setFilter={setFilter}
              />
            }
          />
        </PageTabPanel>
        <PageTabPanel>
          <ExploreCoursesTab />
        </PageTabPanel>
      </PageTabGroup>
    </div>
  )
}

export const getServerSideProps = baseServerSideProps

School.getLayout = getSchoolLayout

export default School
